.form-control.increment-input {
  width: 20px;
}

.existing-increment {
  background-color: #00f500;
}

.take-action {
  background-color: #ff9100;
}
